<template>
	<div class="grid lg:grid-cols-3 gap-5">
		<div class="lg:col-span-2 h-full overflow-y-auto">
			<n-data-table title="位置列表" :columns="fields" :data="locations" actions="view,edit,search,refresh" @view="getLocation" @edit="archiveLocation">
				<template v-slot:name="{ row }"> {{ row.name['tc'] }}</template>
				<template v-slot:merchantId="{ row }"> {{ merchantName(row.merchantId).name['tc'] }}</template>
				<template v-slot:menuIds="{ row }">
					<div>{{ row['menuIds'].length == 0 ? '未選菜單' : null }}</div>
					<div v-for="menuId in row.menuIds" :key="menuId" class="flex space-x-2 w-full">
						<n-badge> {{ catalogItem(menuId).name['tc'] }}</n-badge>
					</div>
				</template>
				<template v-slot:status="{ row }">
					<n-badge :color="statusName(row.status).color"> {{ statusName(row.status).label }}</n-badge>
				</template>
			</n-data-table>
		</div>
		<div class="lg:col-span-1 space-y-2 text-xl h-full overflow-auto">
			<p class="text-4xl pb-2">{{ location.id ? '修改' : '新增' }}位置</p>
			<div class="flex space-x-2">
				<label for="merchant">所屬商戶</label>
				<n-select v-model="location.merchantId" :options="merchants" :reduce="merchant => merchant.id" :get-option-label="merchant => merchant.name['tc']" class="w-full h-full text-base" placeholder="請選擇商戶"></n-select>
			</div>
			<template v-if="location.merchantId">
				<div class="flex space-x-2">
					<label for="code">位置編號</label>
					<n-input id="code" type="text" placeholder="請輸入編號" maxlength="3" v-model="location.code" />
				</div>
				<div class="flex space-x-2">
					<label for="name">位置名稱</label>
					<n-input id="name" type="text" placeholder="請輸入名稱" v-model="location.name['tc']" />
				</div>
				<div class="flex space-x-2">
					<label for="name">店舖菜單</label>
					<n-select v-model="location.menuIds" multiple :options="catalogByMerchantId(location.merchantId, 'MENU')" :reduce="option => option.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇菜單"></n-select>
				</div>
				<div class="flex space-x-2">
					<label for="status">位置狀態</label>
					<n-select v-model="location.status" :options="statusOptions" :reduce="status => status.value" class="w-full h-full text-base" placeholder="請選擇狀態"></n-select>
				</div>
			</template>
			<p v-if="error" class="text-danger">{{ '錯誤訊息:' + error }}</p>

			<div class="flex space-x-2">
				<n-button v-if="location.id" @onClick="$router.go(0)" color="danger">返回</n-button>
				<n-button @onClick="location.id ? updateLocation(location) : createLocation(location)" color="primary">{{ location.id ? '修改位置' : '加入位置' }} </n-button>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { location } from '@/model/location'
export default {
	data() {
		return {
			location: location(),
			fields: [
				{
					label: '位置編號',
					prop: 'code',
					sortable: true,
				},
				{
					label: '位置名稱',
					prop: 'name',
					sortable: true,
				},
				{
					label: '所屬商戶',
					prop: 'merchantId',
					sortable: true,
				},
				{
					label: '已選菜',
					prop: 'menuIds',
				},
				{
					label: '狀態',
					prop: 'status',
					sortable: true,
				},
			],
			error: '',
		}
	},
	computed: {
		...mapState(['statusOptions']),
		...mapGetters([
			// merchant
			'merchants',
			'merchantName',
			// location
			'locations',
			'currentLocation',
			// catalog
			'catalogItem',
			'catalogByMerchantId',
			// state
			'statusName',
		]),
	},
	methods: {
		async createLocation(data) {
			try {
				await this.$store.dispatch('createLocation', { location: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async getLocation(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('getLocation', { id })
				this.location = this.currentLocation
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async archiveLocation(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('archiveLocation', { id })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async updateLocation(data) {
			try {
				this.error = ''
				await this.$store.dispatch('updateLocation', { id: data.id, location: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
	},
}
</script>
