import { locale } from './locale'

export function location() {
	return {
		code: '',
		merchantId: '',
		name: locale(),
		menuIds: [],
		status: '',
	}
}
